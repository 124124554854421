import React, { useState, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import CloseButton from "../close-button";
import ContentHeading from "../content-heading";
import PrivacyText from "../privacy-text";
import { IFormFields, ISignUpForm } from "./interfaces/ISignUpForm";
import { getCookie, setCookie } from "@/lib/cookies";
import { submitForm, activateReCAPTCHA } from "./lib";
// FontAwesome icons
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Tailwind classes
import {
  from,
  inputWrapper,
  inputErrorMessage,
  popupWrapper,
  popupBackDrop,
  popupFormWrapper,
  popupSubHeading,
  popupInputWrapper,
  popupInputs,
  popupErrorMessage,
  popupFormButton,
  closeButton,
  popupDisclaimer,
  input,
  button,
  disclaimer,
  successMessage,
  errorMessage,
} from "./styles/sign-up";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";

const SignUpForm: React.FC<ISignUpForm> = ({
  formId,
  type,
  product = "Not Specified",
  lead = "Website Product Enquiry",
  isPopup,
}) => {
  const { t } = useTranslation();

  // ReCAPTCHA Variables
  const [reCAPTCHAActive, setReCAPTCHAActive] = useState(false);
  const [reCAPTCHAError, setReCAPTCHAError] = useState("");
  // Form variables
  const [showForm, setShowForm] = useState(true);
  const [formIsLoading, setFormIsLoading] = useState(false);
  const [formError, setFormError] = useState({
    error: false,
    field: "",
  } as { error: boolean; field?: string });
  const [thankYouState, setThankYouState] = useState({
    success: false,
    message: "",
  });
  // Form state
  const [formState, setFormState] = useState<IFormFields>({
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL: "",
    LEAD_SOURCE_ID: 3292000,
    LEAD_DESCRIPTION: "",
    PHONE: "",
    CUSTOMFIELDS: [
      {
        FIELD_NAME: "Lead_Source_Secondary__c",
        FIELD_VALUE: lead,
      },
      {
        FIELD_NAME: "hearX_product_multi__c",
        FIELD_VALUE: product,
      },
    ],
  });

  // Form translation values
  const heading = isPopup
    ? t(`forms.popup-forms.${type}.heading`)
    : t(`forms.${type}.heading`);
  const subHeading = isPopup
    ? t(`forms.popup-forms.${type}.subheading`)
    : t(`forms.${type}.subheading`);
  const formButton = isPopup
    ? t(`forms.popup-forms.${type}.button`)
    : t(`forms.${type}.button`);
  const firstNamePlaceholder = t("forms.sign-up.placeholders.first-name");
  const lastNamePlaceholder = t("forms.sign-up.placeholders.last-name");
  const emailPlaceholder = t("forms.sign-up.placeholders.email");

  /*
	Handler to close the popup if the form is a popup
	*/
  const closePopupHandler = () => {
    setShowForm(false);
    const cookiesAccepted = getCookie("cookies_accepted");
    if (!cookiesAccepted) return;
    setCookie("popup_was_closed", "true", 365);
  };

  /*
	Handler to submit the form data
	*/
  const handleSubmit = () => {
    // Setting the state of the form that it is busy processing the request
    setFormIsLoading(true);

    // Submit the request
    submitForm(
      formState,
      (response: { error: boolean; field?: string }) => {
        // Set the form error state
        setFormError(response);

        // Set the form back to ready to process
        setFormIsLoading(false);

        // Set the reCAPTCHA errors to nothing
        setReCAPTCHAError("");

        // Check if there are any errors in the response
        if (response.error) {
          // Set reCAPTCHA Errors for the user to see
          if (response.field) {
            switch (response.field) {
              case "RE_CAPTCHA_NOT_FOUND":
              case "RE_CAPTCHA_NULL":
                setReCAPTCHAError(t("forms.error-messages.re-captcha.empty"));
                break;
              case "RE_CAPTCHA":
                setReCAPTCHAError(t("forms.error-messages.re-captcha.invalid"));
                break;
            }
          }
          return;
        }
        // If the form was requested successfully set this to success === true
        setThankYouState({
          success: true,
          message: t(`forms.success.${type}`),
        });
      },
      ["PHONE"]
    );
  };

  /*
	Handler to update the form state when a field changes its value
	*/
  const onChangeHandler = (event: FormEvent<HTMLInputElement>) => {
    const newFormState = { ...formState } as IFormFields;
    const fieldName = event.currentTarget.name;

    Object.keys(newFormState).forEach((key: string) => {
      if (key === fieldName) {
        newFormState[key] = event.currentTarget.value;
      }
    });

    setFormState(newFormState);
  };

  /*
	Handler to warm up reCAPTCHA
	*/
  const handleReCAPTCHAActivation = () => {
    activateReCAPTCHA((active) => {
      setReCAPTCHAActive(active);
    }).then();
  };

  return showForm ? (
    <div className={isPopup ? popupWrapper : ""}>
      {/* Backdrop */}
      {isPopup && (
        <div
          className={popupBackDrop}
          onClick={() => {
            closePopupHandler();
          }}
        />
      )}

      {/* Form wrapper */}
      <div className={isPopup ? popupFormWrapper : ""}>
        {isPopup && (
          <div className={closeButton}>
            <CloseButton
              closeHandler={() => {
                closePopupHandler();
              }}
            />
          </div>
        )}

        {/* Heading */}
        <ContentHeading
          className={`justify-center ${
            !subHeading.includes("forms.") ? "mb-6" : "mb-12"
          }`}
          h="p"
          color={isPopup ? "text-secondary" : "text-white"}
          background={`${isPopup ? "bg-primary bg-opacity-50" : "bg-accent"}`}
          fontSize={`${isPopup ? "text-3xl" : "text-4xl"}`}
          backdropPosition="left-5"
        >
          {!heading.includes("forms.") ? heading : "Add Heading"}
        </ContentHeading>

        {/* Subheading */}
        {!subHeading.includes("forms.") ? (
          <p className={popupSubHeading}>{subHeading}</p>
        ) : null}

        {/* Form */}
        <form
          id={formId}
          className={classNames(from, "GA4-NewsletterSignup")}
          onClick={() => {
            if (!reCAPTCHAActive) {
              handleReCAPTCHAActivation();
            }
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className={isPopup ? popupInputWrapper : inputWrapper}>
            <input
              className={isPopup ? popupInputs : input}
              type="text"
              name="FIRST_NAME"
              placeholder={firstNamePlaceholder}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              maxLength={26}
              value={formState.FIRST_NAME}
              required
            />
            {formError.error && formError.field === "FIRST_NAME" && (
              <p className={isPopup ? popupErrorMessage : inputErrorMessage}>
                {t("forms.error-messages.first-name.invalid")}
              </p>
            )}
          </div>

          <div className={isPopup ? popupInputWrapper : inputWrapper}>
            <input
              className={isPopup ? popupInputs : input}
              type="text"
              name="LAST_NAME"
              placeholder={lastNamePlaceholder}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              maxLength={26}
              value={formState.LAST_NAME}
              required
            />
            {formError.error && formError.field === "LAST_NAME" && (
              <p className={isPopup ? popupErrorMessage : inputErrorMessage}>
                {t("forms.error-messages.last-name.invalid")}
              </p>
            )}
          </div>

          <div className={isPopup ? popupInputWrapper : inputWrapper}>
            <input
              className={isPopup ? popupInputs : input}
              type="email"
              name="EMAIL"
              placeholder={emailPlaceholder}
              onInput={(e) => {
                onChangeHandler(e);
              }}
              value={formState.EMAIL}
              required
            />
            {formError.error && formError.field === "EMAIL" && (
              <p className={isPopup ? popupErrorMessage : inputErrorMessage}>
                {t("forms.error-messages.email.invalid")}
              </p>
            )}
          </div>

          {/* Check if the form request was success full and display the correct component */}
          {thankYouState.success && thankYouState.message.length > 0 ? (
            <p className={successMessage}>{thankYouState.message}</p>
          ) : (
            <button
              className={isPopup ? popupFormButton : button}
              type="submit"
            >
              {formIsLoading ? (
                <FontAwesomeIcon icon={faSpinner as IconProp} spin={true} />
              ) : (
                formButton
              )}
            </button>
          )}

          {/* ReCAPTCHA errors goes here */}
          {reCAPTCHAError !== "" && (
            <p className={errorMessage}>{reCAPTCHAError}</p>
          )}

          {/* Request errors goes here */}
          {formError.error && formError.field === "REQUEST_ERROR" && (
            <p className={errorMessage}>
              {t("forms.error-messages.request.invalid")}
            </p>
          )}
        </form>
        <div className={isPopup ? popupDisclaimer : disclaimer}>
          <PrivacyText fontSize="text-xs" />
        </div>
      </div>
    </div>
  ) : null;
};

export default SignUpForm;
