import { useState, useEffect } from "react";
import Head from "next/head";
import NavigationBar from "@/components/navigation";
import Footer from "@/components/footer";
import { useTranslation } from "react-i18next";
import { SignUpForm } from "@/components/forms/";
import { getCookie } from "@/lib/cookies";
import { setUTMCampaignCookies } from "@/lib/utm-campaign";
import { CookiePopup } from "@/components/cookie-popup";
import GoogleTagManager from "@/components/google-tag-manager";

const Layout: React.FC<{ page: string; noindex?: boolean }> = ({
  children,
  page,
  noindex,
}) => {
  // i18next translation variables
  const { t } = useTranslation();
  const name = t(`${page}.meta.name`);
  const title = t(`${page}.meta.title`);
  const description = t(`${page}.meta.description`);
  const ogImage = t(`${page}.meta.ogImage`);

  // Popup logic
  const lazyPopupPages = ["index"]; // A list of all the pages the popup should appear
  const [showLazyPopup, setShowLazyPopup] = useState(false);

  // A popup to display after a certain amount of time if there was no interaction with the form in the past
  const LazyPopup: React.FC = () => {
    return showLazyPopup && lazyPopupPages.includes(page) ? (
      <SignUpForm
        formId="newsletter-form-popup"
        product="Not Specified"
        lead="Newsletter"
        type="newsletter"
        isPopup={true}
      />
    ) : null;
  };

  // Only show the popup when the content has loaded, the timer has ran out and the popup was not closed or submitted in the past
  useEffect(() => {
    return; // TODO: Popup is temporarily disabled
    const popupWasClosed = getCookie("popup_was_closed")
      ? getCookie("popup_was_closed")
      : false;

    if (!showLazyPopup && !popupWasClosed) {
      setTimeout(() => {
        setShowLazyPopup(true);
      }, 10000);
    }
  }, [showLazyPopup]);

  // Sets the site UTM cookies
  useEffect(() => {
    setUTMCampaignCookies();
  }, []);

  return (
    <div className="font-raleway">
      <Head>
        {/* BASIC META TAGS */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href={`https://hearxgroup.com/${page}`} />

        {/* OPEN GRAPH TAGS */}
        <meta property="og:title" content={title} />
        <meta property="og:url" content={`https://hearxgroup.com/${name}`} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`https://hearxgroup.com/${ogImage}`}
        />

        {/* TWITTER CARDS */}
        <meta name="twitter:card" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:url" content={`https://hearxgroup.com/${name}`} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={`https://hearxgroup.com/${ogImage}`}
        />

        {/* IOS */}
        <link rel="apple-touch-icon" href="/favicon.ico" />
        <link rel="apple-touch-startup-image" href="/favicon.ico" />
        <meta name="apple-mobile-web-app-title" content={name} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        {noindex && <meta name="robots" content="noindex" />}

        {/* Facebook / Meta domain verification */}
        <meta
          name="facebook-domain-verification"
          content={process.env.NEXT_PUBLIC_FACEBOOK_DOMAIN_VERIFICATION_ID}
        />
      </Head>
      <NavigationBar />
      <main className="mobile:pt-16 tablet:pt-16 text-secondary">
        {children}
      </main>
      <LazyPopup />
      <Footer />
    </div>
  );
};

export default Layout;
